<template>
  <div :id="id" :class="['m-resource-list', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <div class="m-resource-list-content">
      <VisualItem v-if="mainTitle && mainTitle.value" :visualKeys="mainTitle.visualKeys" @visual="handleVisual" class-name="m-main-title">
        <p class="content">
          {{ mainTitle.value }}
        </p>
      </VisualItem>
      <div class="u-list-wrap f-clearfix">
        <VisualItem v-if="isPC" :visualKeys="list.visualKeys" @visual="handleVisual">
          <div
            v-for="(item, index) in list.value"
            :key="`list-${index}`"
            class="m-list f-clearfix"
          >
            <VisualItem :visualKeys="item.insideList.visualKeys" @visual="handleVisual" class="m-list-overflow">
              <div v-for="(insideItem, insideIndex) in item.insideList.value" :key="`insideList-${insideIndex}`" class="m-inside-list f-clearfix">
                <div v-if="(insideItem.img && insideItem.img.value) && (insideItem.img_2x && insideItem.img_2x.value)" class="u-img">
                  <VisualItem :visualKeys="[insideItem.img.visualKeys, insideItem.img_2x.visualKeys]" @visual="handleVisual">
                    <CMSImage
                      :img="insideItem.img.value"
                      :img2x="insideItem.img_2x.value"
                    />
                  </VisualItem>
                </div>
                <div class="u-info">
                  <p :title="insideItem.title.value" class="title">
                    <!-- visualKeys 组合的key 用于获取节点数据、更新节点等操作 -->
                    <VisualItem :visualKeys="insideItem.title.visualKeys" @visual="handleVisual" class="content">
                      {{ insideItem.title.value }}
                    </VisualItem>
                  </p>
                  <p v-if="insideItem.desc1 && insideItem.desc1.value" :title="insideItem.desc1.value" class="desc1">
                    <VisualItem :visualKeys="insideItem.desc1.visualKeys" @visual="handleVisual" class="content">
                      {{ insideItem.desc1.value }}
                    </VisualItem>
                  </p>
                  <p v-if="insideItem.desc2 && insideItem.desc2.value" :title="insideItem.desc2.value" class="desc2">
                    <VisualItem :visualKeys="insideItem.desc2.visualKeys" @visual="handleVisual" class="content">
                      {{ insideItem.desc2.value }}
                    </VisualItem>
                  </p>
                </div>
              </div>
            </VisualItem>
          </div>
          <VisualItem :visualKeys="[moreLink.visualKeys, moreLinkText.visualKeys]" @visual="handleVisual" class-name="more-link">
            <CMSLink :link="moreLink.value" class-name="link">
              {{ moreLinkText.value }}
            </CMSLink>
          </VisualItem>
        </VisualItem>
        <VisualItem v-else :visualKeys="list.visualKeys" @visual="handleVisual" tip="编辑列表">
          <div
            v-for="(item, index) in list.value"
            :key="`list-${index}`"
            :class="['m-list', 'f-clearfix', list.value[index].insideList.value.length > 4 ? 'm-list-some' : '', visible[index] ? 'show-list' : '']"
          >
            <VisualItem :visualKeys="item.insideList.visualKeys" @visual="handleVisual">
              <div v-for="(insideItem, insideIndex) in item.insideList.value" :key="`insideList-${insideIndex}`" class="m-inside-list f-clearfix">
                <div v-if="(insideItem.img_2x_h5 && insideItem.img_2x_h5.value) && (insideItem.img_3x_h5 && insideItem.img_3x_h5.value)" class="u-img">
                  <VisualItem :visualKeys="[insideItem.img_2x_h5.visualKeys, insideItem.img_3x_h5.visualKeys]" @visual="handleVisual">
                    <CMSImage
                      :img="insideItem.img_2x_h5.value"
                      :img2x="insideItem.img_3x_h5.value"
                    />
                  </VisualItem>
                </div>
                <div class="u-info">
                  <p :title="insideItem.title.value" class="title">
                    <!-- visualKeys 组合的key 用于获取节点数据、更新节点等操作 -->
                    <VisualItem :visualKeys="insideItem.title.visualKeys" @visual="handleVisual" class="content">
                      {{ insideItem.title.value }}
                    </VisualItem>
                  </p>
                  <p v-if="insideItem.desc1 && insideItem.desc1.value" :title="insideItem.desc1.value" class="desc1">
                    <VisualItem :visualKeys="insideItem.desc1.visualKeys" @visual="handleVisual" class="content">
                      {{ insideItem.desc1.value }}
                    </VisualItem>
                  </p>
                  <p v-if="insideItem.desc2 && insideItem.desc2.value" :title="insideItem.desc2.value" class="desc2">
                    <VisualItem :visualKeys="insideItem.desc2.visualKeys" @visual="handleVisual" class="content">
                      {{ insideItem.desc2.value }}
                    </VisualItem>
                  </p>
                </div>
              </div>
            </VisualItem>
            <div v-if="list.value[index].insideList.value.length > 4 && !visible[index]" @click="seeMore(index)" class="see-more">
              查看更多<img src="/ResourceList/seeMore.svg">
            </div>
          </div>
        </VisualItem>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import VisualItem from '~/components/common/VisualItem'
import CMSImage from '~/components/common/Image'
import CMSLink from '~/components/common/Link'

const componentKey = 'ResourceList'

export default {
  components: {
    VisualItem,
    CMSImage,
    CMSLink
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data(content) {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data,
      visible: [false, false]
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    console.log(this.list.value[0].insideList.value.length)
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    },
    seeMore(index) {
      this.visible[index] = true
      this.$forceUpdate()
      console.log(this.visible)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-resource-list {
  &.z-web {
    padding: 90px 0;
    background: #f3f5fc;
    border: 1px solid #edf0f5;
    box-sizing: border-box;

    .m-resource-list-content {
      width: 1200px;
      margin: 0 auto;

      .m-main-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 45px;
        color: $mainTitleColor;
        text-align: center;

        .content {
          @mixin ellipsis;
        }
      }

      .u-list-wrap {
        margin-top: 40px;
      }

      .m-list {
        margin-bottom: 40px;

        &-overflow {
          overflow: hidden;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .more-link {
        margin-top: 50px;
        text-align: center;

        .link {
          font-size: 14px;
          line-height: 20px;
          color: #0057ff;
        }
      }

      .m-inside-list {
        width: 400px;
        height: 240px;
        padding: 31px 70px 36px;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 -1px 0 #eaeef1, -1px 0 0 #eaeef1;
        float: left;

        &:first-child {
          padding: 90px 0 0;
          border-radius: 0;
          background-image: url(/ResourceList/pc/firstBg.png);

          .title .content {
            font-size: 28px;
          }

          .desc1 .content {
            letter-spacing: 1px;
          }

          .u-info {
            p {
              color: #fff;
            }
          }

          .u-img {
            display: none;
          }
        }

        .u-img {
          max-width: 70px;
          margin: 0 auto 22px;
        }

        .u-info {
          div {
            @mixin ellipsis;
          }

          .title {
            font-size: 18px;
            line-height: 25px;
            color: $mainTitleColor;
            margin-bottom: 10px;
          }

          .desc1,
          .desc2 {
            font-size: 14px;
            line-height: 24px;
            color: #6f7993;
          }
        }
      }
    }
  }

  &.z-phone {
    padding: pxtorem(52) pxtorem(60);
    background: #f3f5fc;
    border: 1px solid #edf0f5;
    box-sizing: border-box;

    .u-list-wrap {
      margin-top: pxtorem(60);

      .m-list {
        margin-bottom: pxtorem(60);

        &:last-child {
          margin-bottom: 0;
        }

        .m-inside-list {
          height: pxtorem(264);
          padding: pxtorem(34);
          background: #fff;
          border-bottom: 1px solid #eaeef1;

          .u-img {
            max-width: pxtorem(100);
          }

          &:first-child {
            height: pxtorem(240);
            padding: pxtorem(60) pxtorem(120);
            background-image: url(/ResourceList/h5/firstBg.png);
            background-size: cover;
            text-align: center;

            .content {
              color: #fff;
            }

            .title .content {
              margin-bottom: pxtorem(20);
              font-weight: 600;
              font-size: pxtorem(48);
              line-height: pxtorem(68);
            }

            .desc1 .content {
              font-size: pxtorem(28);
              line-height: pxtorem(40);
            }

            .desc2 .content {
              font-size: pxtorem(28);
              line-height: pxtorem(40);
            }
          }

          .u-img {
            margin-right: pxtorem(40);
            float: left;
          }

          .u-info {
            .title {
              font-size: pxtorem(28);
              line-height: pxtorem(40);
              margin-bottom: pxtorem(12);
              color: $mainTitleColor;
            }

            .desc1,
            .desc2 {
              font-size: pxtorem(24);
              line-height: pxtorem(44);
              color: #6f7993;
            }

            .desc1 {
              .content {
                @mixin ellipsis;
              }
            }

            .desc2 .content {
              @mixin ellipsis-line 2;
            }
          }
        }
      }

      .m-list-some {
        height: pxtorem(1132);
        overflow: hidden;
        position: relative;

        .see-more {
          height: pxtorem(100);
          width: 100%;
          position: absolute;
          bottom: 0;
          background: #fff;
          line-height: pxtorem(100);
          text-align: center;
          color: #0459ff;

          img {
            margin-left: pxtorem(14);
          }
        }
      }

      .show-list {
        height: 100%;
      }
    }
  }
}

@media only screen and $webmin {
  .m-resource-list.z-web .m-resource-list-content {
    width: 1200px;

    .m-inside-list {
      width: 400px;
    }
  }
}
</style>
